/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

.page {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}


/* header */

.header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-shrink: 0;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
}

.question_title {
    width: 725px;
    height: 88px;
    font-size: 36px;
    line-height: 44px;
}

.exit {
    width: 210px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-shrink: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
}

.end {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    width: 210px;
    height: 38.5px;
    background: #FFFFFF;
    border: 1px solid #000000;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 10px;
    line-height: 22px;
    -webkit-transition: 0.2s ease-in-out;
    -o-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
    vertical-align: middle;
    font-family: Gilroy;
    font-size: 18px;
    font-weight: bold;
    line-height: 22px;
}

.end:focus {
    outline: 0;
}

.end:hover {
    background-color: black;
    color: white;
    cursor: pointer;
}


/* body */

.question_body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-shrink: 0;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    margin-top: 29px;
}


/* left column */

.tests_body_left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-shrink: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 367.5px;
}


/* right column */

.test_body_right {
    padding-left: 2%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-shrink: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 550px;
}


/* scores frame */

.scores_frame {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-shrink: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
}

.score_frame {
    margin-top: 29px;
    margin-bottom: 29px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-shrink: 0;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    width: 100%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.score {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-shrink: 0;
    margin-bottom: 16px;
    width: 100%;
}

.score {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-shrink: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
    width: 174px;
    height: 100%;
    margin: 0px 7px;
}

.mark {
    font-size: 64px;
    font-weight: bold;
    line-height: 68px;
}

.description {
    width: 330px;
    text-align: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    color: gray;
    margin-top: 19px;
    font-size: 14px;
}

.topic_frame_menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-shrink: 0;
    width: 100%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}


/* btns */

.buttons_frame {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-shrink: 0;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-item-align: center;
    align-self: center;
}

.btn_title {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 7px;
}

.comment {
    font-size: 14px;
    line-height: 18px;
}


/* btn */

.btn_wrap {
    width: 50%;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
}

.btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-shrink: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-line-pack: center;
    align-content: center;
    width: 174px;
    height: 223px;
    border: 1px solid white;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    text-align: center;
    margin: 0px 3.5px;
    padding: 10.5px;
    -webkit-transition: 0.2s linear;
    -o-transition: 0.2s linear;
    transition: 0.2s linear;
}

.btn:focus {
    outline: 0;
}

.btn:hover {
    border: 1px solid black;
    cursor: pointer;
}

.btn_disabled {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-shrink: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-line-pack: center;
    align-content: center;
    width: 174px;
    height: 223px;
    border: 1px solid white;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 10px;
    text-align: center;
    margin: 0px 3.5px;
    padding: 10.5px;
    opacity: 0.4;
    cursor: pointer;
}


/* video */

.wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-shrink: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    vertical-align: middle;
    margin: auto;
}

.question_title_mobile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-shrink: 0;
    width: 100%;
    margin-bottom: 20px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    text-align: center;
    align-self: center;
    color: black;
    margin-top: 20px;
    width: 330px;
    font-size: 25px;
    line-height: 30px;
}

.icon {
    width: 70px;
    height: 70px;
    margin-bottom: 20px;
}


/* skip */

.skip {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-shrink: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 50%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-item-align: center;
    align-self: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}


/* adaptive */

@media (max-width: 700px) {
    .question_title_mobile {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        flex-shrink: 0;
        width: 100%;
        margin-bottom: 20px;
        -webkit-box-align: center;
        -ms-flex-align: center;
        text-align: center;
        align-self: center;
        color: black;
        margin-top: 20px;
        width: 330px;
        font-size: 25px;
        line-height: 30px;
    }
}

@media (max-width: 992px) {
    /* это будет показано при разрешении монитора до 930 пикселей */
    .tests_body_left {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        flex-shrink: 0;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        width: 90%;
        margin-top: 60px;
    }
    .question_body {
        display: flex;
        flex-shrink: 0;
        flex-direction: column;
        width: 95%;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
    .test_body_right {
        padding-left: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        flex-shrink: 0;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        width: 100%;
    }
    .score_frame {
        margin-top: 29px;
        margin-bottom: 15px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        flex-shrink: 0;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        width: 100%;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
    .topic_frame_menu {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        flex-shrink: 0;
        width: 90%;
        -ms-flex-item-align: center;
        align-self: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
    .description {
        width: 60%;
        text-align: center;
        -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
        color: gray;
        margin-top: 20px;
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .tests_body_left {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        flex-shrink: 0;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        width: 90%;
    }
    .btn_wrap {
        width: 80%;
        -ms-flex-item-align: center;
        -ms-grid-row-align: center;
    }
    .skip {
        width: 80%;
        margin: auto;
        align-self: center;
    }
    .title {
        margin-left: 10px;
    }
}

@media (max-width: 325px) {
    /* это будет показано при разрешении монитора до 930 пикселей */
    .question_body {
        display: flex;
        flex-shrink: 0;
        flex-direction: column;
        width: 95%;
        margin-top: 29px;
        align-items: center;
    }
    .tests_body_left {
        display: flex;
        flex-shrink: 0;
        flex-direction: column;
        width: 100%;
        align-self: center;
    }
    .test_body_right {
        display: flex;
        flex-shrink: 0;
        flex-direction: column;
        width: 100%;
        align-self: center;
    }
    .skip {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        flex-shrink: 0;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        width: 60%;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-self: center;
    }
    .title {
        display: flex;
        flex-shrink: 0;
        margin-top: 30px;
    }
    .video_explanation_frame {
        width: 100%;
        height: 90%;
    }
    .video {
        width: 100%;
        height: 250px;
        background-color: black;
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        -ms-flex-item-align: center;
        align-self: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}

@media (min-width: 993px) {
    .page {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        padding: 105px 0px;
        position: absolute;
        width: 950px;
        left: calc(50% - 950px/2);
        top: 0px;
    }
}

@media only screen and (min-height: 750px) and (max-height: 850px) {
    .page {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        flex-shrink: 0;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        padding: 105px 0px;
        position: absolute;
        width: 95%;
        height: 120vh;
        left: calc(50% - 95%/2);
        top: 0px;
        background-color: #FFFFFF;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
}

@media only screen and (min-height: 700px) and (max-height: 749px) {
    .page {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        flex-shrink: 0;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        padding: 10px 0px;
        position: absolute;
        width: 95%;
        height: 100%;
        left: calc(50% - 95%/2);
        top: 0px;
        background-color: #FFFFFF;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
}

@media only screen and (min-height: 600px) and (max-height: 699px) {
    .page {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        flex-shrink: 0;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        padding: 10px 0px;
        position: absolute;
        width: 95%;
        height: 100%;
        left: calc(50% - 95%/2);
        top: 0px;
        background-color: #FFFFFF;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
}

@media only screen and (min-height: 520px) and (max-height: 599px) {
    .page {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        flex-shrink: 0;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        padding: 10px 0px;
        position: absolute;
        width: 95%;
        height: 100%;
        left: calc(50% - 95%/2);
        top: 0px;
        background-color: #FFFFFF;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
}