.end {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    text-align: center;
    width: 210px;
    height: 38.5px;
    background: #FFFFFF;
    border: 1px solid #000000;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    border-radius: 10px;
    line-height: 22px;
    -webkit-transition: 0.2s ease-in-out;
    -o-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
    vertical-align: middle;
    font-family: Gilroy;
    font-size: 25px;
    line-height: 30px;
}

.end:focus {
    outline: 0;
}

.end:hover {
    background-color: black;
    color: white;
    cursor: pointer;
}
.header{
    width: 100%;
    background-color: white;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 25px;
    line-height: 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}

.nav_panel{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding: 0px 15px;
    -webkit-box-align: baseline;
        -ms-flex-align: baseline;
            align-items: baseline;
    margin-top: 15px;
    font-size:18px;
    
}
.nav{
    font-size:25px;
    line-height: 30px;
}
.timer{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
}
.title{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    margin-bottom: 10px;
}

@media (max-width: 992px) {
    /* это будет показано при разрешении монитора до 930 пикселей */
    .header{
        height: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        font-size: 16px;
        line-height: 20px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        height: 20%;
    }
    .page {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        padding: 5px 0px;
        position: absolute;
        width: 95%;
        left: calc(50% - 95%/2);
        top: 0px;
        font-size: 16px;
    }
}