@font-face {
    font-family: Gilroy;
    src: url("https://raw.githubusercontent.com/mavka-org/mavka-web/master/src/fonts/Gilroy-Regular.woff");
}

@font-face {
    font-family: Gilroy;
    src: url("https://raw.githubusercontent.com/mavka-org/mavka-web/master/src/fonts/Gilroy-RegularItalic.woff");
    font-style: italic;
}

@font-face {
    font-family: Gilroy;
    src: url("https://raw.githubusercontent.com/mavka-org/mavka-web/master/src/fonts/Gilroy-Semibold.woff");
    font-weight: bold;
}

@font-face {
    font-family: Gilroy;
    src: url("https://raw.githubusercontent.com/mavka-org/mavka-web/master/src/fonts/Gilroy-SemiboldItalic.wofff");
    font-weight: bold;
    font-style: italic;
}

.page {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: white;
    font-family: Gilroy;
    font-size: 24px;
    line-height: 29px;
}

.wrapper {
    padding-top: 110px;
    padding-left: 110px;
    padding-right: 110px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.frame_1 {
    display: flex;
    flex-direction: column;
    margin-right: 25px;
}

.title {
    display: flex;
    flex-direction: row;
}

.beta {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    text-align: center;
    width: 70px;
    height: 30px;
    background: #0EFB71;
    border-radius: 10px;
    justify-content: center;
    margin-left: 12px;
}

.big_title {
    font-size: 56px;
    line-height: 69px;
    font-weight: 600;
    margin-top: 30px;
    margin-bottom: 30px;
}

.next {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    width: 282px;
    height: 42.5px;
    background: #111111;
    border-radius: 10px;
    color: white;
    border: none;
    font-size: 24px;
    line-height: 29px;
    font-family: Gilroy;
    text-align: center;
    vertical-align: middle;
    transition: 0.3s ease-out;
    border: 1px black solid;
    margin-top: 20px;
    margin-bottom: 20px;
    cursor: pointer;
}

.next:hover {
    color: black;
    background-color: white;
}

.next:focus {
    outline: 0;
}

.desc_2 {
    font-size: 24px;
    line-height: 29px;
    margin: 25px;
}

.icon {
    margin-left: 80px;
    display: block;
    min-width: 65px;
    min-height: 65px;
}

.frame_2 {
    display: flex;
    flex-direction: column;
    border-left: 1px black solid;
}

.icon_row {
    display: flex;
    flex-direction: row;
}

@media screen and (max-width: 814px) {
    .wrapper {
        padding-top: 10px;
        padding-left: 10px;
        padding-right: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
    .frame_2 {
        display: flex;
        flex-direction: column;
        border-left: none;
    }

}