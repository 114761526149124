.course_frame {
    width: 285px;
    height: 120px;
    border: 1px solid #0000002f;
    background-color: white;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    margin: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -moz-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transition: 0.2s linear;
    -o-transition: 0.2s linear;
    transition: 0.2s linear;
    cursor: pointer;
}

.course_frame_no_hover {
    width: 285px;
    height: 120px;
    border: 1px solid #0000002f;
    background-color: white;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    margin: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -moz-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transition: 0.2s linear;
    -o-transition: 0.2s linear;
    transition: 0.2s linear;
    cursor: pointer;
}

.course_frame:hover {
    border: 1px black solid;
}

.wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -moz-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-item-align: center;
    align-self: center;
}

.icon {
    width: 64px;
    height: 64px;
}

.course {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.course_text {
    margin-left: 23px;
    font-weight: 600;
    width: 150px;
}