@font-face {
    font-family: Gilroy;
    src: url("https://raw.githubusercontent.com/mavka-org/mavka-web/master/src/fonts/Gilroy-Regular.woff");
}

@font-face {
    font-family: Gilroy;
    src: url("https://raw.githubusercontent.com/mavka-org/mavka-web/master/src/fonts/Gilroy-RegularItalic.woff");
    font-style: italic;
}

@font-face {
    font-family: Gilroy;
    src: url("https://raw.githubusercontent.com/mavka-org/mavka-web/master/src/fonts/Gilroy-Semibold.woff");
    font-weight: bold;
}

@font-face {
    font-family: Gilroy;
    src: url("https://raw.githubusercontent.com/mavka-org/mavka-web/master/src/fonts/Gilroy-SemiboldItalic.wofff");
    font-weight: bold;
    font-style: italic;
}

.background {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    font-size: 18px;
    font-family: Gilroy;
    background-color: rgba(128, 128, 128, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.alert {
    position: absolute;
    display: flex;
    background-color: white;
    flex-direction: column;
    padding: 30px;
    width: 291.5px;
    height: 342px;
    opacity: 1;
    border: 1px black solid;
    border-radius: 10px;
    align-self: center;
}

.alert_text {
    margin-bottom: 10px;
}

.btn_wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 25px;
}

.btn_turn_back {
    width: 100%;
    height: 38.5px;
    background: #000000;
    border-radius: 10px;
    color: white;
    font-weight: bold;
    font-family: Gilroy;
    font-size: 18px;
    line-height: 22px;
    transition: 0.2s ease-out;
    border: none;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.28);
    align-self: flex-start;
    margin-bottom: 14px;
}

.btn_turn_back:hover {
    opacity: 0.9;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.56);
    cursor: pointer;
}

.btn_turn_back:focus {
    outline: 0;
}

.btn_end {
    width: 100%;
    height: 38.5px;
    background: #FFFFFF;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 10px;
    margin: 0px 0px;
    font-family: Gilroy;
    font-size: 18px;
    line-height: 22px;
    font-weight: bold;
    transition: 0.2s ease-in-out;
}

.btn_end:focus {
    outline: 0;
}

.btn_end:hover {
    background-color: black;
    color: white;
    cursor: pointer;
}

.btn_hard_end {
    font-size: 16px;
    line-height: 20px;
    font-family: Gilroy;
}

.btn_hard_end {
    margin-top: 12px;
    font-size: 18px;
    line-height: 22px;
    color: gray;
    opacity: 0.8;
    text-decoration: underline;
    background-color: #FFFFFF;
    border: none;
    transition: 0.2s ease-in;
}

.btn_hard_end:focus {
    outline: 0;
}

.btn_hard_end:hover {
    color: black;
    cursor: pointer;
}