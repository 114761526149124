.header_mobile {
    -moz-appearance: none;
    appearance: none;
    -webkit-appearance: none;
    width: 90%;
    background-color: white;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-shrink: 0;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 30px;
    padding: 0px 15px;
    margin-top: 15px;
    font-size: 25px;
    line-height: 25px;
}

.logo {
    font-size: 25px;
    line-height: 30px;
}

.header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-shrink: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
}

.question_header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    flex-direction: row;
}

.icon {
    width: 100%;
    height: 100%;
}

.title {
    display: -ms-inline-flexbox;
    display: -webkit-inline-box;
    display: inline-flex;
    flex-shrink: 0;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    font-size: 36px;
    line-height: 44px;
    font-weight: 600;
    margin-bottom: 8px;
}

.sub_title {
    display: inline-block;
    display: -moz-inline-stack;
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
}

.sub_title_icon {
    display: inline-block;
    display: -moz-inline-stack;
}

.description {
    margin-top: 20px;
    margin-bottom: 49px;
}


/* courses */

.courses_wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-shrink: 0;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.exit {
    width: 210px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
}

.wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-shrink: 0;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
}

.end {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    align-items: center;
    text-align: center;
    width: 130px;
    height: 38.5px;
    background: #FFFFFF;
    border: 1px solid #000000;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 10px;
    line-height: 22px;
    -webkit-transition: 0.2s ease-in-out;
    -o-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
    vertical-align: middle;
    font-family: Gilroy;
    font-size: 18px;
    font-weight: bold;
    line-height: 22px;
}

.end:focus {
    outline: 0;
}

.end:hover {
    background-color: black;
    color: white;
    cursor: pointer;
}

@media (max-width: 992px) {
    .page {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        flex-shrink: 0;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        padding: 10px 0px;
        position: absolute;
        -moz-appearance: none;
        appearance: none;
        -webkit-appearance: none;
        height: 220%;
        left: calc(50% - 95%/2);
        top: 0px;
        background-color: #FFFFFF;
    }
}

@media (min-width: 993px) {
    .page {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        padding: 10px 0px;
        position: absolute;
        width: 950px;
        left: calc(50% - 950px/2);
        top: 0px;
        -moz-appearance: none;
        appearance: none;
        -webkit-appearance: none;
    }
}

@media (max-width: 663px) {
    .courses_wrapper {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        flex-shrink: 0;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-pack: distribute;
        justify-content: space-around;
        -ms-flex-item-align: center;
        align-self: center;
        -moz-appearance: none;
        appearance: none;
        -webkit-appearance: none;
    }
}