@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@font-face {
    font-family: Gilroy;
    src: url("https://raw.githubusercontent.com/mavka-org/mavka-web/master/src/fonts/Gilroy-Regular.woff");
}

@font-face {
    font-family: Gilroy;
    src: url("https://raw.githubusercontent.com/mavka-org/mavka-web/master/src/fonts/Gilroy-RegularItalic.woff");
    font-style: italic;
}

@font-face {
    font-family: Gilroy;
    src: url("https://raw.githubusercontent.com/mavka-org/mavka-web/master/src/fonts/Gilroy-Semibold.woff");
    font-weight: bold;
}

@font-face {
    font-family: Gilroy;
    src: url("https://raw.githubusercontent.com/mavka-org/mavka-web/master/src/fonts/Gilroy-SemiboldItalic.wofff");
    font-weight: bold;
    font-style: italic;
}

.NotFound_wrapper__3X-uB {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.NotFound_image__mw9OP{
    text-align: center;

}
.NotFound_alert__2z_y_ {
    font-family: Gilroy;
    display: flex;
    flex-direction: row;
    font-size: 28px;
    line-height: 33px;
    align-content: center;
    justify-content: center;
    margin-bottom: 20px;

}


@media (max-width: 600px) {
    .NotFound_alert__2z_y_ > img{
       width: 100%;
       height: 100%;
    }


}

@font-face {
    font-family: Gilroy;
    src: url("https://raw.githubusercontent.com/mavka-org/mavka-web/master/src/fonts/Gilroy-Regular.woff");
}

@font-face {
    font-family: Gilroy;
    src: url("https://raw.githubusercontent.com/mavka-org/mavka-web/master/src/fonts/Gilroy-RegularItalic.woff");
    font-style: italic;
}

@font-face {
    font-family: Gilroy;
    src: url("https://raw.githubusercontent.com/mavka-org/mavka-web/master/src/fonts/Gilroy-Semibold.woff");
    font-weight: bold;
}

@font-face {
    font-family: Gilroy;
    src: url("https://raw.githubusercontent.com/mavka-org/mavka-web/master/src/fonts/Gilroy-SemiboldItalic.wofff");
    font-weight: bold;
    font-style: italic;
}

.Login_Register_page__2puCE {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.Login_Register_window__3Owte {
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    width: 282px;
    height: 500px;
    margin: auto;
    font-family: Gilroy;
}

.Login_Register_logo__2pYE0 {
    color: black;
    font-size: 18px;
    -ms-grid-row-align: center;
    align-self: center;
    margin-bottom: 15px;
}

.Login_Register_logo__2pYE0:focused {
    color: black;
}

.Login_Register_header__1egFe {
    font-size: 36px;
    -ms-grid-row-align: center;
    align-self: center;
}

.Login_Register_errMsg__38D_K {
    text-align: center;
}

.Login_Register_btns__WCWrt {
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
}

.Login_Register_btn_google__3uDsi {
    width: 282px;
    height: 42.5px;
    background: #FFBE0B;
    border-radius: 10px;
    border: white;
    -ms-grid-row-align: center;
    align-self: center;
    margin: 25px;
    font-family: Gilroy;
    font-size: 20px;
    line-height: 25px;
    transition: 0.3s ease-out;
    font-weight: 600;
    cursor: pointer;
}

.Login_Register_btn_google__3uDsi:focus {
    outline: 0;
}

.Login_Register_btn_google__3uDsi:hover {
    background: black;
    color: white;
}

.Login_Register_btns__WCWrt {
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    align-items: center;
}

.Login_Register_info__112hU {
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    margin: 10px 25px 25px 25px;
    font-family: Gilroy;
}

.Login_Register_btn__1QQA8 {
    font-family: Gilroy;
    width: 282px;
    height: 42.5px;
    background: #FFFFFF;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 10px;
    -ms-grid-row-align: center;
    align-self: center;
    margin: 10px 25px 25px 25px;
    transition: 0.3s ease-out;
    font-size: 20px;
    line-height: 25px;
    font-weight: 600;
    cursor: pointer;
}

.Login_Register_btn__1QQA8:focus {
    outline: 0;
}

.Login_Register_btn__1QQA8:hover {
    background-color: black;
    color: white;
}

.Login_Register_inp_wrapper__PqKYd>input {
    font-size: Gilroy;
    width: 282px;
    height: 42.5px;
    left: 0px;
    top: 14px;
    background: #E5E5E5;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    border-radius: 10px;
    font-size: 18px;
    padding: 8px 16px;
    line-height: 22px;
}

.Login_Register_password_wrapper__2yeVU {
    display: flex;
    flex-shrink: 0;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 3px;
}

.Login_Register_inp_wrapper__PqKYd>input:focus {
    outline: 0;
}

.Login_Register_account__ViFVj {
    color: gray;
    text-decoration: underline;
    cursor: pointer;
}

.Login_Register_agreement__30UoR {
    font-size: 11px;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 5px;
    color: gray;
}
@font-face {
    font-family: Gilroy;
    src: url("https://raw.githubusercontent.com/mavka-org/mavka-web/master/src/fonts/Gilroy-Regular.woff");
}

@font-face {
    font-family: Gilroy;
    src: url("https://raw.githubusercontent.com/mavka-org/mavka-web/master/src/fonts/Gilroy-RegularItalic.woff");
    font-style: italic;
}

@font-face {
    font-family: Gilroy;
    src: url("https://raw.githubusercontent.com/mavka-org/mavka-web/master/src/fonts/Gilroy-Semibold.woff");
    font-weight: bold;
}

@font-face {
    font-family: Gilroy;
    src: url("https://raw.githubusercontent.com/mavka-org/mavka-web/master/src/fonts/Gilroy-SemiboldItalic.wofff");
    font-weight: bold;
    font-style: italic;
}
@font-face {
    font-family: Gilroy;
    src: url("https://raw.githubusercontent.com/mavka-org/mavka-web/master/src/fonts/Gilroy-Bold.woff");
    font-weight: bolder;
}

@font-face {
    font-family: Gilroy;
    src: url("https://raw.githubusercontent.com/mavka-org/mavka-web/master/src/fonts/Gilroy-BoldItalic.wofff");
    font-weight: bolder;
    font-style: italic;
}

.Style_page___1JesJ {
    background-color: #FFFFFF;
    font-family: Gilroy;
    font-size: 18px;
    line-height: 25px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.Style_question_header__at3M0 {
    width: 100%;
    height: 40px;
    margin-bottom: 29px;
}

.Style_icon_header__3Wgkm {
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.Style_title_bar__3e5vq {
    font-size: 22px;
    line-height: 30px;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
}

.Style_end__2CjO6 {
    width: 157.5px;
    height: 38.5px;
    background: #FFFFFF;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 10px;
    margin: 0px 0px;
    font-family: Gilroy;
    font-size: 18px;
    line-height: 25px;
    font-weight: bold;
    transition: 0.2s ease-in-out;
}

.Style_end__2CjO6:focus {
    outline: 0;
}

.Style_end__2CjO6:hover {
    background-color: black;
    color: white;
    cursor: pointer;
}

/* result */

.Style_result_frame__qy-_x {
    margin-top: 30px;
    margin-bottom: 30px;
    width: 314px;
    padding: 0px 16px;
}

.Style_result__19i2I {
    font-size: 22px;
    line-height: 27px;
    margin-bottom: 14px;
}

.Style_stats__A5wjG {
    margin-bottom: 14px;
    width: 282px;
}

.Style_wrap__3Pyfj {
    display: flex;
    align-items: center;
    flex-direction: column;
    vertical-align: middle;
}

.Style_btn__2Xiun {
    width: 100%;
    height: 38.5px;
    background: #000000;
    border-radius: 10px;
    color: white !important;
    font-weight: bold;
    font-family: Gilroy;
    font-size: 18px;
    line-height: 25px;
    transition: 0.2s ease-out;
    border: none;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.28);
    align-self: flex-start;
    margin-bottom: 14px;
}

.Style_btn__2Xiun:hover {
    opacity: 0.9;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.56);
    cursor: pointer;
}

.Style_btn__2Xiun:focus {
    outline: 0;
}

.Style_pass__xcT5G {
    font-size: 18px;
    line-height: 25px;
    color: gray;
    opacity: 0.8;
    text-decoration: underline;
    background-color: #FFFFFF;
    border: none;
    transition: 0.2s ease-in;
}

.Style_report__1qocN {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    font-size: 14px;
    margin-top: 10px;
    text-decoration: none !important;
}

.Style_pass__xcT5G:focus {
    outline: 0;
}

.Style_pass__xcT5G:hover {
    color: black;
    cursor: pointer;
}

/* topic */

.Style_topic_frame__1cH4z {}

.Style_topic_frame__1cH4z>div {}

.Style_topic_frame__1cH4z>p {}

.Style_comment_frame__1xTFy {}

.Style_list__3wCKL {
    display: -ms-flexbox;
}
.Style_topic_frame__1cH4z {

}

.Style_topic_frame__1cH4z > div {

}
.Style_topic_frame__1cH4z > p {

}

.Style_comment_frame__1xTFy {

}
.Style_list__3wCKL{
    display: flex;
    width: 100%;
    flex-direction: column;
}

.Style_list__3wCKL>div {
    width: 100%;
    word-wrap: normal;
    margin: 10px 0px;
}

.Style_video__3YdtN {
    width: 459px;
    height: 275.4px;
    background-color: black;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Style_video_text__p_-ws {
    margin: 0 auto;
    color: white;
    position: absolute;
    font-size: 22px;
    font-family: Gilroy;
    line-height: 29px;
}

.Style_btnqnav__19ciR {
    font-family: Gilroy;
    font-size: 18px;
}

.Style_btnqnav__19ciR:focus {
    outline: 0;
}

.Style_inactive__1wlq3 {
    opacity: 0.4;
    cursor: default;
    border: none !important;
}

.Style_inactive__1wlq3:hover {
    border: 1px white solid !important;
}

.Style_wrong_answer__1QALJ {
    opacity: 0.6;
}

.Style_inactiveNextButton__12YSC {
    opacity: 0.4;
    cursor: default;
}

.Style_inactiveNextButton__12YSC:hover {
    opacity: 0.4;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.28);
    cursor: default;
}

.Style_variant_anwer__zzgRa {
    display: flex;
    flex-direction: row;
    padding: 10px;
}

.Style_variant_anwer__zzgRa>label {
    width: "15px";
    height: "15px";
    border-radius: "15px";
    border: "1px solid black";
}

.Style_back__dkjIM {
    color: black;
}

.Style_back__dkjIM:focus {
    outline: 0;
}

/* mediaquery */

@media (max-width: 992px) {
    .Style_video__3YdtN {
        width: 339px;
        height: 165.4px;
        background-color: black;
        margin-bottom: 30px;
    }
}

@media (max-width: 992px) {
    .Style_end__2CjO6 {
        width: 30%;
        height: 80%;
        background: #FFFFFF;
        border: 1px solid #000000;
        box-sizing: border-box;
        border-radius: 10px;
        margin: 0px 0px;
        font-family: Gilroy;
        font-size: 14px;
        line-height: 18px;
        font-weight: bold;
        transition: 0.2s ease-in-out;
    }
}

.Style_links__5dptX {
    margin: 10px 0px;
    cursor: pointer;
}


.Style_links__5dptX:hover {
    opacity: 1;
}

@font-face {
    font-family: Gilroy;
    src: url("https://raw.githubusercontent.com/mavka-org/mavka-web/master/src/fonts/Gilroy-Regular.woff");
}

@font-face {
    font-family: Gilroy;
    src: url("https://raw.githubusercontent.com/mavka-org/mavka-web/master/src/fonts/Gilroy-RegularItalic.woff");
    font-style: italic;
}

@font-face {
    font-family: Gilroy;
    src: url("https://raw.githubusercontent.com/mavka-org/mavka-web/master/src/fonts/Gilroy-Semibold.woff");
    font-weight: bold;
}

@font-face {
    font-family: Gilroy;
    src: url("https://raw.githubusercontent.com/mavka-org/mavka-web/master/src/fonts/Gilroy-SemiboldItalic.wofff");
    font-weight: bold;
    font-style: italic;
}
.LoadingScreen_page__3qvBC{
    position: absolute;
    left:0;
    top:0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 55px;
    line-height: 60px;
    font-family: Gilroy;
}


.LoadingScreen_loading__xE9fL{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px ;
    justify-content: center;
    color:black;
    width: 350px;
    height: 100px;
}

.LoadingScreen_logo__1J_ud{
    -webkit-animation: LoadingScreen_pulse__2NsF0 2s infinite linear;
            animation: LoadingScreen_pulse__2NsF0 2s infinite linear;
}

@-webkit-keyframes LoadingScreen_pulse__2NsF0{
    0%{
        opacity: 1;
    }
    50%{
        opacity: 0.5;
    }
    100%{
        opacity: 1;
    }
}

@keyframes LoadingScreen_pulse__2NsF0{
    0%{
        opacity: 1;
    }
    50%{
        opacity: 0.5;
    }
    100%{
        opacity: 1;
    }
}

@font-face {
    font-family: Gilroy;
    src: url("https://raw.githubusercontent.com/mavka-org/mavka-web/master/src/fonts/Gilroy-Regular.woff");
}

@font-face {
    font-family: Gilroy;
    src: url("https://raw.githubusercontent.com/mavka-org/mavka-web/master/src/fonts/Gilroy-RegularItalic.woff");
    font-style: italic;
}

@font-face {
    font-family: Gilroy;
    src: url("https://raw.githubusercontent.com/mavka-org/mavka-web/master/src/fonts/Gilroy-Semibold.woff");
    font-weight: bold;
}

@font-face {
    font-family: Gilroy;
    src: url("https://raw.githubusercontent.com/mavka-org/mavka-web/master/src/fonts/Gilroy-SemiboldItalic.wofff");
    font-weight: bold;
    font-style: italic;
}

.AlertConfirm_background__1YoPk {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    font-size: 18px;
    font-family: Gilroy;
    background-color: rgba(128, 128, 128, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.AlertConfirm_alert__1donU {
    position: absolute;
    display: flex;
    background-color: white;
    flex-direction: column;
    padding: 30px;
    width: 291.5px;
    height: 200px;
    opacity: 1;
    border: 1px black solid;
    border-radius: 10px;
    align-self: center;
}

.AlertConfirm_alert_text__1t6Ci {
    margin-bottom: 10px;
}

.AlertConfirm_btn_wrapper__3dNRd {
    display: flex;
    flex-direction: column;
    margin-top: 25px;
}

.AlertConfirm_btn_turn_back__3evQu {
    width: 100%;
    height: 38.5px;
    background: #000000;
    border-radius: 10px;
    color: white;
    font-weight: bold;
    font-family: Gilroy;
    font-size: 18px;
    line-height: 22px;
    transition: 0.2s ease-out;
    border: none;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.28);
    align-self: flex-start;
    margin-bottom: 14px;
}

.AlertConfirm_btn_turn_back__3evQu:hover {
    opacity: 0.9;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.56);
    cursor: pointer;
}

.AlertConfirm_btn_turn_back__3evQu:focus {
    outline: 0;
}

.AlertConfirm_btn_end__3qPuS {
    width: 100%;
    height: 38.5px;
    background: #FFFFFF;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 10px;
    margin: 0px 0px;
    font-family: Gilroy;
    font-size: 18px;
    line-height: 22px;
    font-weight: bold;
    transition: 0.2s ease-in-out;
    margin-bottom: 14px;
}

.AlertConfirm_btn_end__3qPuS:focus {
    outline: 0;
}

.AlertConfirm_btn_end__3qPuS:hover {
    background-color: black;
    color: white;
    cursor: pointer;
}

.AlertConfirm_btn_hard_end__3TvP4 {
    font-size: 16px;
    line-height: 20px;
    font-family: Gilroy;
}

.AlertConfirm_btn_hard_end__3TvP4 {
    margin-top: 12px;
    font-size: 18px;
    line-height: 22px;
    color: gray;
    opacity: 0.8;
    text-decoration: underline;
    background-color: #FFFFFF;
    border: none;
    transition: 0.2s ease-in;
}

.AlertConfirm_btn_hard_end__3TvP4:focus {
    outline: 0;
}

.AlertConfirm_btn_hard_end__3TvP4:hover {
    color: black;
    cursor: pointer;
}
.ABCDE_page__3x7v3 {
    display: flex;
    flex-direction: column;
    padding: 105px 0px;
    position: absolute;
    width: 945px;
    left: calc(50% - 945px/2);
    top: 0px;
}

/* body */

.ABCDE_question_body__1uYd5 {
    display: flex;
    flex-direction: row;
    padding: 0px;
    width: 945px;
    height: 100%;
    background-color: #FFFFFF;
}

/* qustion_form */

.ABCDE_question_form__1jWZJ {
    display: flex;
    flex-direction: column;
    width: 46%;
    margin-top: 20px;
}

/* video frame */

.ABCDE_video__1bnD2 {
    width: 459px;
    height: 275.4px;
    background-color: black;
}

/* answer frame */

.ABCDE_answers_frame__1Jqfe {
    display: flex;
    flex-direction: column;
    padding: 0px;
    align-self: flex-start;
    width: 48%;
    margin-left: 5%;
}

.ABCDE_wrap__E0C-x {
    display: flex;
    flex-direction: column;
    vertical-align: middle;
    width: 62%;
}

.ABCDE_pass__1ldnx {
    font-size: 18px;
    line-height: 22px;
    color: gray;
    opacity: 0.8;
    text-decoration: underline;
    background-color: #FFFFFF;
    border: none;
    margin-top: 16px;
}
.Answer_answer__kqLol {
    display: flex;
    flex-direction: column;
    padding: 2% 16px;
    min-width: 96%;
    max-width: 96%;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
    border: 1px white solid;
    border-radius: 10px;
    align-self: center;
    margin-bottom: 7px;
    transition: 0.2s linear;
    cursor: pointer;
}

.Answer_answer_withouthover__U5N84 {
    display: flex;
    flex-direction: column;
    padding: 2% 16px;
    min-width: 96%;
    max-width: 96%;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
    border: 1px white solid;
    border-radius: 10px;
    align-self: center;
    margin-bottom: 7px;
    transition: 0.2s linear;
}

.Answer_answer_text__3-lyl {
    font-size: 18px;
    margin-bottom: 16px;
    line-height: 25px;
}

.Answer_answer__kqLol:focus {
    outline: 0;
}

.Answer_answer__kqLol:hover {
    border: 1px grey solid;
}

.Answer_answer_answered__2FXIc {
    border: 1px black solid;
}

.Answer_answer_text_frame__1uB0q {
    display: inline-flex;
    align-items: baseline;
    flex-direction: row;
    padding: 0px;
    flex: none;
    align-self: flex-start;
    font-size: 16px;
    line-height: 20px;
    margin-top: 21px;
}

.Answer_letter_choice__jFHlX {
    vertical-align: middle;
    font-size: 22px;
    line-height: 27px;
    margin-right: 21px;
}

.Answer_choose__tlh2Z {
    font-size: 18px;
    line-height: 25px;
}

.Answer_check__1GSzr {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.Answer_symbol__2ra0n>svg {
    padding-bottom: 4px;
    padding-right: 8px;
    vertical-align: middle;
}

.Answer_comment__3Spz1 {
    font-size: 15px;
    line-height: 23px;
    margin-left: 16px;
    margin-bottom: 16px;
}
a {
    text-decoration: none;
}

ul {
    list-style: none;
    margin: 0;
    padding-left: 0;
    padding-right: 0;
}

li {
    color: #000000;
    display: block;
    float: left;
    position: relative;
    text-decoration: none;
    transition-duration: 0.5s;
}

li a {
    color: #000000;
}

li:hover {
    cursor: pointer;
}

ul li ul {
    border: 1px solid black;
    position: absolute;
    border-radius: 5px;
    visibility: hidden;
    opacity: 0;
    min-width: 108px;
    transition: all 0.5s ease;
    display: none;
}

ul li:hover>ul, ul li:focus-within>ul,
/* this is the line we add */

ul li ul:hover {
    visibility: visible;
    opacity: 1;
    display: block;
}

ul li ul li {
    clear: both;
    width: 100%;
}
@font-face {
    font-family: Gilroy;
    src: url("https://raw.githubusercontent.com/mavka-org/mavka-web/master/src/fonts/Gilroy-Regular.woff");
}

@font-face {
    font-family: Gilroy;
    src: url("https://raw.githubusercontent.com/mavka-org/mavka-web/master/src/fonts/Gilroy-RegularItalic.woff");
    font-style: italic;
}

@font-face {
    font-family: Gilroy;
    src: url("https://raw.githubusercontent.com/mavka-org/mavka-web/master/src/fonts/Gilroy-Semibold.woff");
    font-weight: bold;
}

@font-face {
    font-family: Gilroy;
    src: url("https://raw.githubusercontent.com/mavka-org/mavka-web/master/src/fonts/Gilroy-SemiboldItalic.wofff");
    font-weight: bold;
    font-style: italic;
}

.Alert_background__1ZqVj {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    font-size: 18px;
    font-family: Gilroy;
    background-color: rgba(128, 128, 128, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Alert_alert__KphOX {
    position: absolute;
    display: flex;
    background-color: white;
    flex-direction: column;
    padding: 30px;
    width: 291.5px;
    height: 342px;
    opacity: 1;
    border: 1px black solid;
    border-radius: 10px;
    align-self: center;
}

.Alert_alert_text__1pZaz {
    margin-bottom: 10px;
}

.Alert_btn_wrapper__2dTsi {
    display: flex;
    flex-direction: column;
    margin-top: 25px;
}

.Alert_btn_turn_back__3J7Wy {
    width: 100%;
    height: 38.5px;
    background: #000000;
    border-radius: 10px;
    color: white;
    font-weight: bold;
    font-family: Gilroy;
    font-size: 18px;
    line-height: 22px;
    transition: 0.2s ease-out;
    border: none;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.28);
    align-self: flex-start;
    margin-bottom: 14px;
}

.Alert_btn_turn_back__3J7Wy:hover {
    opacity: 0.9;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.56);
    cursor: pointer;
}

.Alert_btn_turn_back__3J7Wy:focus {
    outline: 0;
}

.Alert_btn_end__2qL8_ {
    width: 100%;
    height: 38.5px;
    background: #FFFFFF;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 10px;
    margin: 0px 0px;
    font-family: Gilroy;
    font-size: 18px;
    line-height: 22px;
    font-weight: bold;
    transition: 0.2s ease-in-out;
}

.Alert_btn_end__2qL8_:focus {
    outline: 0;
}

.Alert_btn_end__2qL8_:hover {
    background-color: black;
    color: white;
    cursor: pointer;
}

.Alert_btn_hard_end__30hUM {
    font-size: 16px;
    line-height: 20px;
    font-family: Gilroy;
}

.Alert_btn_hard_end__30hUM {
    margin-top: 12px;
    font-size: 18px;
    line-height: 22px;
    color: gray;
    opacity: 0.8;
    text-decoration: underline;
    background-color: #FFFFFF;
    border: none;
    transition: 0.2s ease-in;
}

.Alert_btn_hard_end__30hUM:focus {
    outline: 0;
}

.Alert_btn_hard_end__30hUM:hover {
    color: black;
    cursor: pointer;
}
.HeaderMobile_page__3ieAy {
    display: flex;
    flex-direction: column;
    padding: 105px 0px;
    position: absolute;
    width: 945px;
    left: calc(50% - 945px/2);
    top: 0px;
}

.HeaderMobile_header__JOQMM {
    width: 100%;
    height: 350px;
    background-color: white;
    display: flex;
    font-size: 18px;
    line-height: 22px;
    flex-direction: column;
}

.HeaderMobile_nav_panel__3PMZ2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 15px;
    align-items: baseline;
    margin-top: 15px;
}

.HeaderMobile_tip__1n9H- {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px;
    margin-top: 15px;
    background-color: #E8E8E8;
}

.HeaderMobile_tip1__3n34W {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px;
    margin-top: 15px;
    margin-bottom: 5px;
    background-color: white;
}

.HeaderMobile_timer__2SnjH {
    display: flex;
    flex-direction: row;
}

.HeaderMobile_title__W3w55 {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}

@media (max-width: 992px) {
    /* это будет показано при разрешении монитора до 930 пикселей */
    .HeaderMobile_page__3ieAy {
        display: flex;
        flex-direction: column;
        padding: 5px 0px;
        position: absolute;
        width: 95%;
        left: calc(50% - 95%/2);
        top: 0px;
        font-size: 16px;
    }
}

@media (max-width: 992px) {
    /* это будет показано при разрешении монитора до 930 пикселей */
    .HeaderMobile_header__JOQMM {
        width: 100%;
        height: 100%;
        display: flex;
        font-size: 16px;
        line-height: 20px;
        flex-direction: column;
    }
}

@media (min-width: 993px) {
    .HeaderMobile_page__3ieAy {
        display: flex;
        flex-direction: column;
        padding: 105px 0px;
        position: absolute;
        width: 950px;
        left: calc(50% - 950px/2);
        top: 0px;
    }
}

/* overlay */

.HeaderMobile_overlay__NYJwe {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: white;
    overflow-x: hidden;
    transition: 0.5s;
}

.HeaderMobile_overlay-content__3y9Mv {
    position: relative;
    top: 25%;
    width: 60%;
    text-align: center;
    margin-top: 30px;
}

.HeaderMobile_overlay__NYJwe a {
    padding: 8px;
    text-decoration: none;
    font-size: 36px;
    color: black;
    display: block;
    transition: 0.3s;
}

.HeaderMobile_overlay__NYJwe a:hover, .HeaderMobile_overlay__NYJwe a:focus {
    color: black;
}

.HeaderMobile_overlay__NYJwe .HeaderMobile_closebtn__DMJxi {
    position: absolute;
    top: 1%;
    left: 83%;
    font-size: 60px;
}
.Survey_btn_turn_back__Ie6z5 {
    width: 170px;
    height: 48.5px;
    background: #000000;
    border-radius: 10px;
    color: white;
    font-weight: bold;
    font-family: Gilroy;
    font-size: 18px;
    line-height: 22px;
    transition: 0.2s ease-out;
    border: none;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.28);
    align-self: center;
    margin-top: 14px;
    margin-bottom: 7px;
}

.Survey_btn_turn_back__Ie6z5:hover {
    opacity: 0.9;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.56);
    cursor: pointer;
}

.Survey_page__5qT3K {
    display: flex;
    flex-direction: column;
    padding: 55px 0px;
    position: absolute;
    height: 120%;
    width: 945px;
    left: calc(50% - 945px/2);
    top: 0px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}
.Survey_header__3MJon{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.Survey_title__3VnAj{
    font-size: 36px;
    line-height: 44px;
    font-weight: 600;
    margin-bottom: 25px;
}
.Survey_description__sAad7{
    margin-bottom: 49px;
}


/* courses */
.Survey_courses_wrapper__dEq2C{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
}
.ABCD_page__2jT90 {
    display: flex;
    flex-direction: column;
    padding: 105px 0px;
    position: absolute;
    width: 945px;
    left: calc(50% - 945px/2);
    top: 0px;
}

/* body */

.ABCD_question_body__1PI_8 {
    display: flex;
    flex-direction: row;
    padding: 0px;
    width: 945px;
    height: 100%;
    background-color: #FFFFFF;
}

/* qustion_form */

.ABCD_question_form__154rj {
    display: flex;
    flex-direction: column;
    width: 46%;
    margin-top: 20px;
}

/* video frame */

.ABCD_video__3rBIm {
    width: 459px;
    height: 275.4px;
    background-color: black;
}

/* answer frame */

.ABCD_answers_frame__bDcpY {
    display: flex;
    flex-direction: column;
    padding: 0px;
    align-self: flex-start;
    width: 48%;
    margin-left: 4.5%;
}

.ABCD_wrap__1kT87 {
    display: flex;
    flex-direction: column;
    vertical-align: middle;
    width: 62%;
}

.ABCD_pass__2lqjT {
    font-size: 18px;
    line-height: 22px;
    color: gray;
    opacity: 0.8;
    text-decoration: underline;
    background-color: #FFFFFF;
    border: none;
    margin-top: 16px;
}
.Logic_couples_4_4_background__1A2Pg {
    font-family: 'Gilroy';
}

/* main */

.Logic_couples_4_4_page__3zq95 {
    display: flex;
    flex-direction: column;
    padding: 105px 0px;
    position: absolute;
    width: 945px;
    height: 180%;
    left: calc(50% - 945px/2);
    top: 0px;
    background-color: #FFFFFF;
}

.Logic_couples_4_4_question_body__16_A6 {
    display: flex;
    flex-direction: row;
    padding: 0px;
    position: static;
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
}

.Logic_couples_4_4_wrap__1Wcb9 {
    display: flex;
    flex-direction: column;
    vertical-align: middle;
    width: 66%;
}

/* answer */

.Logic_couples_4_4_answers_frame__1SpQe {
    display: flex;
    flex-direction: column;
    padding: 0px;
    align-self: flex-start;
}

.Logic_couples_4_4_answers__1C-vw {
    display: flex;
    flex-direction: column;
    padding: 10px 0px;
    align-self: flex-start;
    margin-bottom: 7px;
    margin-left: 20px;
}

.Logic_couples_4_4_answer_wrap__2e0uA {
    display: flex;
    flex-direction: column;
    width: 33%;
    margin: 0px 10px;
}

/* table */

.Logic_couples_4_4_marker__3ljVV {
    width: 27px;
    height: 27px;
    background-color: #BBC8DA;
    text-align: center;
    vertical-align: auto;
    font-weight: 500;
}

.Logic_couples_4_4_marker__3ljVV p {
    margin: auto;
}

.Logic_couples_4_4_marker_and_text__isLn1 {
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.Logic_couples_4_4_marker__3ljVV {
    margin-right: 15px;
}

/* table */

.Logic_couples_4_4_select_answer__qn_EG {
    display: table;
    border-collapse: separate;
    box-sizing: border-box;
    border-spacing: 2px;
    border-color: grey;
    border-spacing: 8px 8px;
}

.Logic_couples_4_4_select_answer__qn_EG label {
    display: inline-block;
    width: 27px;
    height: 27px;
    line-height: 27px;
    background: #efefef;
    vertical-align: middle;
    align-items: center;
}

.Logic_couples_4_4_select_answer__qn_EG label>input[type=radio] {
    display: none;
}

.Logic_couples_4_4_select_answer__qn_EG label>span {
    background: #fff;
    border: solid 1px #bbb;
    display: inline-block;
    width: 26px;
    height: 26px;
    vertical-align: middle;
}

.Logic_couples_4_4_select_answer__qn_EG input[type=radio]:checked+span {
    background: rgb(0, 0, 0);
}

.Logic_couples_4_4_select_answer__qn_EG span:hover {
    background: rgba(0, 0, 0, 0.274);
}
.Couple_Answer_answer__-eFyT {
    display: flex;
    flex-direction: column;
    padding: 2% 16px;
    min-width: 85%;
    max-width: 50%;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
    border: 1px white solid;
    border-radius: 10px;
    align-self: center;
    margin-bottom: 7px;
    transition: 0.2s linear;
}

.Couple_Answer_answer_withouthover__3JM0f {
    display: flex;
    flex-direction: column;
    padding: 2% 16px;
    min-width: 85%;
    max-width: 50%;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
    border: 1px white solid;
    border-radius: 10px;
    align-self: center;
    margin-bottom: 7px;
    transition: 0.2s linear;
}

.Couple_Answer_answer_text__1PTaU {
    font-size: 18px;
    margin-bottom: 16px;
}

.Couple_Answer_answer__-eFyT:focus {
    outline: 0;
}

.Couple_Answer_answer__-eFyT:hover {
    border: 1px black solid;
    cursor: pointer;
}

.Couple_Answer_borderr__218Yf {
    border: 1px black solid;
}

.Couple_Answer_answer_text_frame__3d411 {
    display: inline-flex;
    align-items: baseline;
    flex-direction: row;
    padding: 0px;
    flex: none;
    align-self: flex-start;
    font-size: 16px;
    line-height: 20px;
    margin-top: 21px;
    margin-bottom: 16px;
}

.Couple_Answer_answer_text__1PTaU {
    margin-left: 21px;
}

.Couple_Answer_letter_choice__3rKQE {
    vertical-align: middle;
    font-size: 22px;
    line-height: 27px;
    margin-right: 21px;
}

.Couple_Answer_choose__3n0n- {
    font-size: 18px;
    line-height: 22px;
}

.Couple_Answer_check__1rKpM {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.Couple_Answer_symbol__2z5w0>svg {
    padding-bottom: 4px;
    padding-right: 8px;
    vertical-align: middle;
}

.Couple_Answer_comment__cdtu9 {
    font-size: 15px;
    line-height: 19px;
    margin-left: 16px;
    margin-bottom: 16px;
}
.CoupleOption_answer__2n53l {
    display: flex;
    flex-direction: column;
    padding: 2% 16px;
    min-width: 96%;
    max-width: 96%;
    background: #FFFFFF;
    border-radius: 10px;
    align-self: center;
    margin-bottom: 7px;
    transition: 0.2s linear;
}

.CoupleOption_answer_text__2yfY9 {
    font-size: 18px;
    margin-bottom: 16px;
}

.CoupleOption_answer_text_frame__GzYSC {
    display: inline-flex;
    align-items: baseline;
    flex-direction: row;
    padding: 0px;
    flex: none;
    align-self: flex-start;
    font-size: 16px;
    line-height: 20px;
    margin-top: 21px;
}

.CoupleOption_letter_choice__2qORS {
    vertical-align: middle;
    font-size: 22px;
    line-height: 27px;
    margin-right: 21px;
}

.CoupleOption_check__wCDoT {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    align-items: baseline;
}
.Logic_couples_4_5_background__ChXFR {
    font-family: 'Gilroy';
}

/* main */

.Logic_couples_4_5_page__3Szf0 {
    display: flex;
    flex-direction: column;
    padding: 105px 0px;
    position: absolute;
    width: 945px;
    height: 220%;
    left: calc(50% - 945px/2);
    top: 0px;
    background-color: #FFFFFF;
}

.Logic_couples_4_5_wrap__2SjM8 {
    display: flex;
    flex-direction: column;
    vertical-align: middle;
    width: 65%;
}

.Logic_couples_4_5_question_body__2a31h {
    display: flex;
    flex-direction: column;
    padding: 0px;
    width: 945px;
    height: 100%;
    background-color: #FFFFFF;
}

.Logic_couples_4_5_question_frame__3DQa1 {
    display: flex;
    flex-direction: column;
    width: 100%;
}

/* answer */

.Logic_couples_4_5_answers_frame__3I2j3 {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-content: space-between;
}

.Logic_couples_4_5_answers__2z2Nh {
    display: flex;
    flex-direction: row;
}

.Logic_couples_4_5_answer_wrap__ISXlc {
    display: flex;
    flex-direction: column;
    width: 33%;
    margin: 0px 10px;
}

/* table */

.Logic_couples_4_5_marker__2Hl3Q {
    width: 27px;
    height: 27px;
    background-color: #BBC8DA;
    text-align: center;
    vertical-align: auto;
    font-weight: 500;
}

.Logic_couples_4_5_marker__2Hl3Q p {
    margin: auto;
}

.Logic_couples_4_5_marker_and_text__21xgq {
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.Logic_couples_4_5_marker__2Hl3Q {
    margin-right: 15px;
}

/* table */

.Logic_couples_4_5_select_answer__2Epfo {
    display: table;
    border-collapse: separate;
    box-sizing: border-box;
    border-spacing: 2px;
    border-color: grey;
    border-spacing: 8px 8px;
}

.Logic_couples_4_5_select_answer__2Epfo label {
    display: inline-block;
    width: 27px;
    height: 27px;
    line-height: 27px;
    background: #efefef;
    vertical-align: middle;
    align-items: center;
}

.Logic_couples_4_5_select_answer__2Epfo label>input[type=radio] {
    display: none;
}

.Logic_couples_4_5_select_answer__2Epfo label>span {
    background: #fff;
    border: solid 1px #bbb;
    display: inline-block;
    width: 26px;
    height: 26px;
    vertical-align: middle;
}

.Logic_couples_4_5_select_answer__2Epfo input[type=radio]:checked+span {
    background: rgb(0, 0, 0);
    border: 2px black solid;
}

.Logic_couples_4_5_select_answer__2Epfo span:hover {
    background: rgba(0, 0, 0, 0.274);
    cursor: pointer;
}
.Double_Open_background__3smDc {
    font-family: 'Gilroy';
}

/* main */

.Double_Open_page__1gdbW {
    display: flex;
    flex-direction: column;
    padding: 105px 0px;
    position: absolute;
    width: 945px;
    height: 190%;
    left: calc(50% - 945px/2);
    top: 0px;
    background-color: #FFFFFF;
}

.Double_Open_inp__1ApMo {
    padding: 8px 16px;
    width: 102px;
    height: 33px;
    background: #FFFFFF;
    border: 1px solid black;
    box-sizing: border-box;
    border-radius: 10px;
    align-self: flex-start;
    font-size: 18px;
    line-height: 22px;
    font-family: 'Gilroy';
}

.Double_Open_inp__1ApMo:focus {
    outline: 0;
    border: 1px black solid;
}

/* answers */

.Double_Open_answers_frame__3YgIo {
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
}

.Double_Open_answer__2eIM2 {
    width: 50%;
    display: flex;
    flex-direction: column;
}

.Double_Open_variant__vd9Wl {
    display: inline-flex;
    align-items: baseline;
    flex-direction: row;
    padding: 0px;
    flex: none;
    align-self: flex-start;
    font-size: 16px;
    line-height: 20px;
    margin-top: 21px;
}

.Double_Open_variant__vd9Wl>div {
    font-size: 18px;
    line-height: 22px;
}

.Double_Open_number__3GxLx {
    vertical-align: middle;
    font-size: 22px;
    line-height: 27px;
    margin-right: 21px;
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.Double_Open_icon__15M9p>svg {
    padding-bottom: 4px;
    padding-right: 8px;
    vertical-align: middle;
}

.Double_Open_comment__2fpS7 {
    font-size: 16px;
}

.Double_Open_check__30HGK {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.Double_Open_correct_answer__VMNpx {
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.Double_Open_correct_number__2wsJr {
    margin-top: 17px;
}

/* result */

.Double_Open_wrap__2-f_S {
    display: flex;
    flex-direction: column;
    vertical-align: middle;
    width: 30%;
}
.Input_Answer_inp__2k6Yv {
    margin-top: 10px;
    margin-left: 25px;
    padding: 8px 16px;
    width: 102px;
    height: 33px;
    background: #FFFFFF;
    border: 1px solid #7c7c7c;
    box-sizing: border-box;
    border-radius: 10px;
    align-self: flex-start;
    font-size: 18px;
    line-height: 22px;
    font-family: 'Gilroy';
}

.Input_Answer_inp__2k6Yv:focus {
    outline: 0;
    border: 1px solid black;
}

.Input_Answer_answer__2kcTw {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.Input_Answer_variant__5_oAH {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 0px;
    flex: none;
    align-self: flex-start;
    font-size: 16px;
    line-height: 20px;
    margin-top: 21px;
}

.Input_Answer_variant__5_oAH>div {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    line-height: 22px;
}

.Input_Answer_number__qB2_1 {
    vertical-align: middle;
    font-size: 22px;
    line-height: 27px;
    margin-right: 21px;
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.Input_Answer_icon__1R69t>svg {
    vertical-align: middle;
}

.Input_Answer_comment__it7vr {
    font-size: 16px;
}

.Input_Answer_check__qeZJ4 {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.Input_Answer_correct_answer__17fIi {
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.Input_Answer_correct_number__qvHNc {
    margin-top: 17px;
}
.Open_background__26cIz {
    font-family: 'Gilroy';
}

/* main */

.Open_page__1RF4N {
    display: flex;
    flex-direction: column;
    padding: 105px 0px;
    position: absolute;
    width: 945px;
    height: 100%;
    left: calc(50% - 945px/2);
    top: 0px;
    background-color: #FFFFFF;
}

/* answers */

.Open_main_answers__7o0JU>p {
    font-size: 18px;
}

.Open_answers_frame__xg0Po {
    display: flex;
    flex-direction: row;
}

.Open_answer__k3bgu {
    width: 50;
    display: flex;
    flex-direction: column;
}

.Open_variant__wbLV3 {
    display: flex;
    flex-direction: row;
    font-size: 18px;
}

.Open_inp__24b8Q {
    padding: 8px 16px;
    width: 102px;
    height: 33px;
    background: #FFFFFF;
    border: 1px solid #E8E8E8;
    box-sizing: border-box;
    border-radius: 10px;
    align-self: flex-start;
    font-size: 18px;
    line-height: 22px;
    font-family: 'Gilroy';
}

.Open_inp__24b8Q:focus {
    outline: 0;
}

.Open_correct_answer__2Gndl {
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.Open_correct_number__14pHI {
    margin-top: 17px;
    font-size: 18px;
    line-height: 22px;
}

.Open_comment__rDFco {
    font-size: 16px;
}

/* result */

.Open_result__8a4FE {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 30px;
}

.Open_result_frame__7lgPB {
    display: flex;
    flex-direction: column;
    padding: 16px;
}

.Open_correct_message__1knAr {
    font-weight: bold;
    font-size: 22px;
    line-height: 27px;
}

.Open_correct_result__14h7j {
    font-size: 18px;
    margin-top: 14px;
}

.Open_btn__1xLE2 {
    width: 282px;
    height: 38.5px;
    background: #000000;
    border-radius: 10px;
    margin-top: 14px;
    color: white;
    font-size: 18px;
    line-height: 22px;
    font-family: 'Gilroy';
    font-weight: bold;
}
.Super_Open_background__36_pN {
    font-family: 'Gilroy';
}

/* main */

.Super_Open_page__cCwBU {
    display: flex;
    flex-direction: column;
    padding: 105px 0px;
    position: absolute;
    width: 945px;
    height: 100%;
    left: calc(50% - 945px/2);
    top: 0px;
    background-color: #FFFFFF;
}

.Super_Open_question_header__poMH0 {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 85.5px;
}

.Super_Open_title_bar__2JANn {
    font-size: 22px;
    line-height: 27px;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
}

.Super_Open_title__xSSv4 {
    font-size: 18px;
    line-height: 22px;
}

.Super_Open_end__2Z1kE {
    width: 157.5px;
    height: 42.5px;
    background: #FFFFFF;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 10px;
    margin: 0px 0px;
    font-size: 18px;
    line-height: 22px;
    font-family: 'Gilroy';
}

.Super_Open_question_frame__27QSm p {
    font-size: 18px;
    line-height: 22px;
}

/* result */

.Super_Open_result__354Dn {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 30px;
}

.Super_Open_result_frame__ulFqh {
    display: flex;
    flex-direction: column;
    padding: 16px;
    width: 298px;
    height: 205px;
}

.Super_Open_correct_message__2Ip92 {
    font-weight: bold;
    font-size: 22px;
    line-height: 27px;
}

.Super_Open_correct_result__1uqAG {
    font-size: 18px;
    margin-top: 14px;
}

.Super_Open_btn__2STcR {
    width: 298px;
    height: 42.5px;
    background: #000000;
    border-radius: 10px;
    margin-top: 14px;
    color: white;
    font-size: 18px;
    line-height: 22px;
    font-family: 'Gilroy';
    font-weight: 600;
}

.Super_Open_topic_frame__1bE3L {
    font-size: 18px;
    line-height: 24px;
}

.Super_Open_stats__3sSLb {
    font-size: 18px;
    line-height: 22px;
}

.Super_Open_topic_frame_text__2XFdy {
    font-size: 18px;
    line-height: 22px;
}

.Super_Open_comment_frame__2VAqJ {
    font-size: 18px;
    line-height: 24px;
}

.Super_Open_comment_frame_text__1g2s6 {
    font-size: 18px;
    line-height: 22px;
}

.Super_Open_video_explanation_frame__1ZzGn {
    font-size: 18px;
    line-height: 24px;
}

/* video frame */

.Super_Open_video__19GzF {
    width: 459px;
    height: 275.4px;
    background-color: black;
}
.ABCDE_OneColumn_background__2gvkf {
    font-family: 'Gilroy';
}

/* main */

.ABCDE_OneColumn_background__2gvkf {
    display: flex;
    flex-direction: column;
    padding: 105px 0px;
    position: absolute;
    width: 945px;
    height: 270%;
    left: calc(50% - 945px/2);
    top: 0px;
    background-color: #FFFFFF;
}

.ABCDE_OneColumn_page__14tdw {
    display: flex;
    flex-direction: column;
    padding: 105px 0px;
    position: absolute;
    width: 945px;
    height: 270%;
    left: calc(50% - 945px/2);
    top: 0px;
    background-color: #FFFFFF;
}

.ABCDE_OneColumn_question_body__1oW_g {
    display: flex;
    flex-direction: column;
    padding: 0px;
    position: static;
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
}

.ABCDE_OneColumn_title__11fP0 {
    font-size: 20px;
    line-height: 24px;
}

/* answer */

.ABCDE_OneColumn_answers_frame__1-P7- {
    display: flex;
    width: 95%;
    flex-direction: column;
    padding: 0px;
    align-self: flex-start;
}

.ABCDE_OneColumn_answers__3z2zb {
    display: flex;
    width: 95%;
    flex-direction: column;
    padding: 10px 0px;
    align-self: flex-start;
    margin-bottom: 7px;
    margin-left: 20px;
}

/* result frame */

.ABCDE_OneColumn_wrap__2fu2t {
    display: flex;
    width: 30%;
    flex-direction: column;
    vertical-align: middle;
}

/* video frame */

@media (max-width: 992px) {
    .ABCDE_OneColumn_page__14tdw {
        display: flex;
        flex-direction: column;
        padding: 10px 0px;
        position: absolute;
        width: 95%;
        height: 180%;
        left: calc(50% - 95%/2);
        top: 0px;
    }
}

@media (min-width: 993px) {
    .ABCDE_OneColumn_page__14tdw {
        display: flex;
        flex-direction: column;
        padding: 105px 0px;
        position: absolute;
        width: 950px;
        height: 150%;
        left: calc(50% - 950px/2);
        top: 0px;
    }
}
.ABCD_OneColumn_page__3eLnj {
    display: flex;
    flex-direction: column;
    padding-top: 105px;
    margin-bottom: 105px;
    position: absolute;
    width: 945px;
    left: calc(50% - 945px/2);
    height: 400%;
    top: 0px;
    background-color: #FFFFFF;
}

.ABCD_OneColumn_question_body__2ILbn {
    display: flex;
    flex-direction: column;
    padding: 0px;
    position: static;
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
}


/* answer */

.ABCD_OneColumn_answers_frame__ePVZC {
    display: flex;
    flex-direction: column;
    padding: 0px;
    width: 95%;
    align-self: flex-start;
}

.ABCD_OneColumn_answers__GXj4n {
    display: flex;
    flex-direction: column;
    padding: 10px 0px;
    align-self: flex-start;
    margin-bottom: 7px;
    margin-left: 20px;
    width: 95%;
}

.ABCD_OneColumn_wrap__3fxZf {
    display: flex;
    width: 31%;
    flex-direction: column;
    vertical-align: middle;
}

@media (max-width: 992px) {
    /* это будет показано при разрешении монитора до 930 пикселей */
    .ABCD_OneColumn_page__3eLnj {
        display: flex;
        flex-direction: column;
        padding: 105px 0px;
        position: absolute;
        width: 95%;
        height: 250%;
        left: calc(50% - 95%/2);
        top: 0px;
        background-color: #FFFFFF;
    }
}

@media (min-width: 993px) {
    .ABCD_OneColumn_page__3eLnj {
        display: flex;
        flex-direction: column;
        padding: 105px 0px;
        position: absolute;
        width: 950px;
        height: 250%;
        left: calc(50% - 950px/2);
        top: 0px;
        background-color: #FFFFFF;
    }
}
.Double_Open_OneColumn_background__14Cor {
    font-family: 'Gilroy';
}

/* main */

.Double_Open_OneColumn_page__1c7P4 {
    display: flex;
    flex-direction: column;
    padding: 105px 0px;
    position: absolute;
    width: 945px;
    height: 220%;
    left: calc(50% - 945px/2);
    top: 0px;
    background-color: #FFFFFF;
}

.Double_Open_OneColumn_inp__25NYq {
    padding: 8px 16px;
    width: 102px;
    height: 33px;
    background: #FFFFFF;
    border: 1px solid #E8E8E8;
    box-sizing: border-box;
    border-radius: 10px;
    align-self: flex-start;
    font-size: 18px;
    line-height: 22px;
    font-family: 'Gilroy';
}

.Double_Open_OneColumn_inp__25NYq:focus {
    outline: 0;
}

/* answers */

.Double_Open_OneColumn_answers_frame__2jjnc {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
}

.Double_Open_OneColumn_answer__23XYU {
    width: 50%;
    display: flex;
    flex-direction: column;
}

.Double_Open_OneColumn_variant__3b_rl {
    display: inline-flex;
    align-items: baseline;
    flex-direction: row;
    padding: 0px;
    flex: none;
    align-self: flex-start;
    font-size: 16px;
    line-height: 20px;
    margin-top: 21px;
}

.Double_Open_OneColumn_variant__3b_rl>div {
    font-size: 18px;
    line-height: 22px;
}

.Double_Open_OneColumn_number__WTM8S {
    vertical-align: middle;
    font-size: 22px;
    line-height: 27px;
    margin-right: 21px;
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.Double_Open_OneColumn_icon__3yC8r>svg {
    padding-bottom: 4px;
    padding-right: 8px;
    vertical-align: middle;
}

.Double_Open_OneColumn_comment__N0kw_ {
    font-size: 16px;
}

.Double_Open_OneColumn_check__3cV84 {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.Double_Open_OneColumn_correct_answer__21PdX {
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.Double_Open_OneColumn_correct_number__3_9B5 {
    margin-top: 17px;
}

/* result */

.Double_Open_OneColumn_wrap__Xy8ZS {
    display: flex;
    flex-direction: column;
    vertical-align: middle;
    width: 30%;
}

@media (max-width: 992px) {
    .Double_Open_OneColumn_page__1c7P4 {
        display: flex;
        flex-direction: column;
        padding: 105px 0px;
        position: absolute;
        width: 95%;
        height: 220%;
        left: calc(50% - 95%/2);
        top: 0px;
    }
}

@media (min-width: 993px) {
    .Double_Open_OneColumn_page__1c7P4 {
        display: flex;
        flex-direction: column;
        padding: 105px 0px;
        position: absolute;
        width: 950px;
        height: 220%;
        left: calc(50% - 950px/2);
        top: 0px;
    }
}
.Logic_Couples_4_4_OneColumn_background__3c8WC {
    font-family: 'Gilroy';
}

/* main */

.Logic_Couples_4_4_OneColumn_page__vI14b {
    display: flex;
    flex-direction: column;
    padding: 105px 0px;
    position: absolute;
    width: 945px;
    height: 280%;
    left: calc(50% - 945px/2);
    top: 0px;
    background-color: #FFFFFF;
}

.Logic_Couples_4_4_OneColumn_question_body__3JGdI {
    display: flex;
    flex-direction: column;
    padding: 0px;
    position: static;
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
}

.Logic_Couples_4_4_OneColumn_wrap__soE2y {
    display: flex;
    flex-direction: column;
    vertical-align: middle;
    width: 30%;
}

/* answer */

.Logic_Couples_4_4_OneColumn_answers_frame__3Fsgd {
    display: flex;
    flex-direction: column;
    padding: 0px;
    align-self: flex-start;
}

.Logic_Couples_4_4_OneColumn_answers__3UCL8 {
    display: flex;
    flex-direction: column;
    padding: 10px 0px;
    align-self: flex-start;
    margin-bottom: 7px;
    margin-left: 20px;
}

/* table */

.Logic_Couples_4_4_OneColumn_marker__3c3k1 {
    width: 27px;
    height: 27px;
    background-color: #BBC8DA;
    text-align: center;
    vertical-align: auto;
    font-weight: 500;
}

.Logic_Couples_4_4_OneColumn_marker__3c3k1 p {
    margin: auto;
}

.Logic_Couples_4_4_OneColumn_marker_and_text__2TVIK {
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.Logic_Couples_4_4_OneColumn_marker__3c3k1 {
    margin-right: 15px;
}

/* table */

.Logic_Couples_4_4_OneColumn_select_answer__meDf9 {
    display: table;
    border-collapse: separate;
    box-sizing: border-box;
    border-spacing: 2px;
    border-color: grey;
    border-spacing: 8px 8px;
}

.Logic_Couples_4_4_OneColumn_select_answer__meDf9 label {
    display: inline-block;
    width: 27px;
    height: 27px;
    line-height: 27px;
    background: #efefef;
    vertical-align: middle;
    align-items: center;
}

.Logic_Couples_4_4_OneColumn_select_answer__meDf9 label>input[type=radio] {
    display: none;
}

.Logic_Couples_4_4_OneColumn_select_answer__meDf9 label>span {
    background: #fff;
    border: solid 1px #bbb;
    display: inline-block;
    width: 26px;
    height: 26px;
    vertical-align: middle;
}

.Logic_Couples_4_4_OneColumn_select_answer__meDf9 input[type=radio]:checked+span {
    background: rgb(0, 0, 0);
}

.Logic_Couples_4_4_OneColumn_select_answer__meDf9 span:hover {
    background: rgba(0, 0, 0, 0.274);
}

@media (max-width: 992px) {
    /* это будет показано при разрешении монитора до 930 пикселей */
    .Logic_Couples_4_4_OneColumn_page__vI14b {
        display: flex;
        flex-direction: column;
        padding: 105px 0px;
        position: absolute;
        width: 95%;
        height: 280%;
        left: calc(50% - 95%/2);
        top: 0px;
    }
}

@media (min-width: 993px) {
    .Logic_Couples_4_4_OneColumn_page__vI14b {
        display: flex;
        flex-direction: column;
        padding: 105px 0px;
        position: absolute;
        width: 950px;
        height: 280%;
        left: calc(50% - 950px/2);
        top: 0px;
    }
}
.Logic_Couples_4_5_OneColumn_page__2poEe {
    display: flex;
    flex-direction: column;
    padding: 105px 0px;
    position: absolute;
    width: 945px;
    height: 300%;
    left: calc(50% - 945px/2);
    top: 0px;
    background-color: #FFFFFF;
}

.Logic_Couples_4_5_OneColumn_question_body__29Ule {
    display: flex;
    flex-direction: column;
    padding: 0px;
    position: static;
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
}

.Logic_Couples_4_5_OneColumn_wrap__1G5br {
    display: flex;
    flex-direction: column;
    vertical-align: middle;
    width: 30%;
}

/* answer */

.Logic_Couples_4_5_OneColumn_answers_frame__1mN5q {
    display: flex;
    flex-direction: column;
    padding: 0px;
    align-self: flex-start;
    width: 90%;
}

.Logic_Couples_4_5_OneColumn_answers__2K9by {
    display: flex;
    flex-direction: column;
    padding: 10px 0px;
    align-self: flex-start;
    margin-bottom: 7px;
    margin-left: 20px;
    width: 90%;
}

/* table */

.Logic_Couples_4_5_OneColumn_marker__3F1uZ {
    width: 27px;
    height: 27px;
    background-color: #BBC8DA;
    text-align: center;
    vertical-align: auto;
    font-weight: 500;
}

.Logic_Couples_4_5_OneColumn_marker__3F1uZ p {
    margin: auto;
}

.Logic_Couples_4_5_OneColumn_marker_and_text__3Y9VF {
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.Logic_Couples_4_5_OneColumn_marker__3F1uZ {
    margin-right: 15px;
}

/* table */

.Logic_Couples_4_5_OneColumn_select_answer__2Anxy {
    display: table;
    border-collapse: separate;
    box-sizing: border-box;
    border-spacing: 2px;
    border-color: grey;
    border-spacing: 8px 8px;
}

.Logic_Couples_4_5_OneColumn_select_answer__2Anxy label {
    display: inline-block;
    width: 27px;
    height: 27px;
    line-height: 27px;
    background: #efefef;
    vertical-align: middle;
    align-items: center;
}

.Logic_Couples_4_5_OneColumn_select_answer__2Anxy label>input[type=radio] {
    display: none;
}

.Logic_Couples_4_5_OneColumn_select_answer__2Anxy label>span {
    background: #fff;
    border: solid 1px #bbb;
    display: inline-block;
    width: 26px;
    height: 26px;
    vertical-align: middle;
}

.Logic_Couples_4_5_OneColumn_select_answer__2Anxy input[type=radio]:checked+span {
    background: rgb(0, 0, 0);
}

.Logic_Couples_4_5_OneColumn_select_answer__2Anxy span:hover {
    background: rgba(0, 0, 0, 0.274);
}

@media (max-width: 992px) {
    /* это будет показано при разрешении монитора до 930 пикселей */
    .Logic_Couples_4_5_OneColumn_page__2poEe {
        display: flex;
        flex-direction: column;
        padding: 105px 0px;
        position: absolute;
        width: 95%;
        height: 300%;
        left: calc(50% - 95%/2);
        top: 0px;
    }
}

@media (min-width: 993px) {
    .Logic_Couples_4_5_OneColumn_page__2poEe {
        display: flex;
        flex-direction: column;
        padding: 105px 0px;
        position: absolute;
        width: 950px;
        height: 300%;
        left: calc(50% - 950px/2);
        top: 0px;
    }
}
.BioTriples_page__10Z0z {
    display: flex;
    flex-direction: column;
    padding: 105px 0px;
    position: absolute;
    width: 945px;
    left: calc(50% - 945px/2);
    top: 0px;
}

.BioTriples_question_body__2ggN_ {
    display: flex;
    flex-direction: row;
    padding: 0px;
    width: 945px;
    background-color: #FFFFFF;
    justify-content: space-around;
}

.BioTriples_answers__399g8 {
    display: flex;
    flex-direction: column;
    width: 30%;
    height: 100%;
}

.BioTriples_title_column__1p81A {
    margin-bottom: 20px;
}

@media (max-width: 992px) {
    /* это будет показано при разрешении монитора до 930 пикселей */
    .BioTriples_page__10Z0z {
        display: flex;
        flex-direction: column;
        align-self: center;
        padding: 5px 0px;
        position: absolute;
        width: 95%;
        left: calc(50% - 95%/2);
        top: 0px;
    }
    .BioTriples_question_body__2ggN_ {
        display: flex;
        flex-direction: column;
        align-content: center;
        padding: 0px;
        width: 95%;
        background-color: #FFFFFF;
        justify-content: space-around;
    }
    .BioTriples_answers__399g8 {
        display: flex;
        flex-direction: column;
        align-self: center;
        width: 90%;
    }
}

@media (min-width: 993px) {
    .BioTriples_page__10Z0z {
        display: flex;
        flex-direction: column;
        padding: 105px 0px;
        position: absolute;
        width: 950px;
        width: 945px;
        left: calc(50% - 950px/2);
        top: 0px;
    }
}
.Geo_History_3_7_page__EOoS5 {
    display: flex;
    flex-direction: column;
    padding: 105px 0px;
    position: absolute;
    width: 945px;
    left: calc(50% - 945px/2);
    top: 0px;
}

.Geo_History_3_7_question_body__2DnPL {
    display: flex;
    flex-direction: row;
    padding: 0px;
    width: 945px;
    height: auto;
    background-color: #FFFFFF;
    justify-content: space-around;
}

.Geo_History_3_7_answers__2B_4_ {
    display: flex;
    flex-direction: column;
    width: 40%;
}

@media (max-width: 992px) {
    /* это будет показано при разрешении монитора до 930 пикселей */
    .Geo_History_3_7_page__EOoS5 {
        display: flex;
        flex-direction: column;
        padding: 5px 0px;
        position: absolute;
        width: 95%;
        left: calc(50% - 95%/2);
        top: 0px;
    }
    .Geo_History_3_7_question_body__2DnPL {
        display: flex;
        flex-direction: column;
        padding: 0px;
        width: 95%;
        background-color: #FFFFFF;
        justify-content: space-around;
    }
    .Geo_History_3_7_answers__2B_4_ {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
}

@media (min-width: 993px) {
    .Geo_History_3_7_page__EOoS5 {
        display: flex;
        flex-direction: column;
        padding: 105px 0px;
        position: absolute;
        width: 950px;
        left: calc(50% - 950px/2);
        top: 0px;
    }
}
.Survey_btn_turn_back__2e4aq {
    width: 170px;
    height: 48.5px;
    background: #000000;
    border-radius: 10px;
    color: white;
    font-weight: bold;
    font-family: Gilroy;
    font-size: 18px;
    line-height: 22px;
    transition: 0.2s ease-out;
    border: none;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.28);
    align-self: center;
    margin-top: 14px;
    margin-bottom: 7px;
}

.Survey_btn_turn_back__2e4aq:hover {
    opacity: 0.9;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.56);
    cursor: pointer;
}

.Survey_page__D2rra {
    display: flex;
    flex-direction: column;
    padding: 55px 0px;
    position: absolute;
    height: 120%;
    width: 945px;
    left: calc(50% - 945px/2);
    top: 0px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}
.Survey_header__RhJ9Y{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.Survey_title__1D7ko{
    font-size: 36px;
    line-height: 44px;
    font-weight: 600;
    margin-bottom: 25px;
}
.Survey_description__2U28d{
    margin-bottom: 49px;
}


/* courses */
.Survey_courses_wrapper__Oq6w_{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
}
/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

.MainMenu_page__2bokk {
    align-items: center;
}


/* header */

.MainMenu_header__3GAHd {
    display: flex;
    flex-shrink: 0;
    flex-direction: row;
}

.MainMenu_question_title__2N5x8 {
    width: 725px;
    height: 88px;
    font-size: 36px;
    line-height: 44px;
}

.MainMenu_exit__pT10X {
    width: 210px;
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    align-items: flex-end;
}

.MainMenu_end__13Utl {
    align-items: center;
    text-align: center;
    width: 210px;
    height: 38.5px;
    background: #FFFFFF;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 10px;
    line-height: 22px;
    transition: 0.2s ease-in-out;
    vertical-align: middle;
    font-family: Gilroy;
    font-size: 18px;
    font-weight: bold;
    line-height: 22px;
}

.MainMenu_end__13Utl:focus {
    outline: 0;
}

.MainMenu_end__13Utl:hover {
    background-color: black;
    color: white;
    cursor: pointer;
}


/* body */

.MainMenu_question_body__2oCrI {
    display: flex;
    flex-shrink: 0;
    flex-direction: row;
    margin-top: 29px;
}


/* left column */

.MainMenu_tests_body_left__2BpLE {
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    width: 367.5px;
}


/* right column */

.MainMenu_test_body_right__3rh4k {
    padding-left: 2%;
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    width: 550px;
}


/* scores frame */

.MainMenu_scores_frame__2YKBU {
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    width: 100%;
}

.MainMenu_score_frame__3Uat4 {
    margin-top: 29px;
    margin-bottom: 29px;
    display: flex;
    flex-shrink: 0;
    flex-direction: row;
    width: 100%;
    align-items: center;
}

.MainMenu_score__2gaj8 {
    display: flex;
    flex-shrink: 0;
    margin-bottom: 16px;
    width: 100%;
}

.MainMenu_score__2gaj8 {
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    text-align: center;
    width: 174px;
    height: 100%;
    margin: 0px 7px;
}

.MainMenu_mark__2ch-i {
    font-size: 64px;
    font-weight: bold;
    line-height: 68px;
}

.MainMenu_description__J0eJW {
    width: 330px;
    text-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    color: gray;
    margin-top: 19px;
    font-size: 14px;
}

.MainMenu_topic_frame_menu___hAEN {
    display: flex;
    flex-shrink: 0;
    width: 100%;
    align-items: center;
    justify-content: center;
}


/* btns */

.MainMenu_buttons_frame__208Xp {
    display: flex;
    flex-shrink: 0;
    flex-direction: row;
    align-self: center;
}

.MainMenu_btn_title__1CasJ {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 7px;
}

.MainMenu_comment__SB-Go {
    font-size: 14px;
    line-height: 18px;
}


/* btn */

.MainMenu_btn_wrap__3UqrG {
    width: 50%;
    -ms-grid-row-align: center;
    align-self: center;
}

.MainMenu_btn__aHbXa {
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    align-content: center;
    width: 174px;
    height: 223px;
    border: 1px solid white;
    box-sizing: border-box;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    text-align: center;
    margin: 0px 3.5px;
    padding: 10.5px;
    transition: 0.2s linear;
}

.MainMenu_btn__aHbXa:focus {
    outline: 0;
}

.MainMenu_btn__aHbXa:hover {
    border: 1px solid black;
    cursor: pointer;
}

.MainMenu_btn_disabled__1wMFu {
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    align-content: center;
    width: 174px;
    height: 223px;
    border: 1px solid white;
    box-sizing: border-box;
    border-radius: 10px;
    text-align: center;
    margin: 0px 3.5px;
    padding: 10.5px;
    opacity: 0.4;
    cursor: pointer;
}


/* video */

.MainMenu_wrap__2Dkj9 {
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    align-items: center;
    vertical-align: middle;
    margin: auto;
}

.MainMenu_question_title_mobile__2tfKY {
    display: flex;
    flex-shrink: 0;
    width: 100%;
    margin-bottom: 20px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    text-align: center;
    align-self: center;
    color: black;
    margin-top: 20px;
    width: 330px;
    font-size: 25px;
    line-height: 30px;
}

.MainMenu_icon__qt56A {
    width: 70px;
    height: 70px;
    margin-bottom: 20px;
}


/* skip */

.MainMenu_skip__2W6RZ {
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    width: 50%;
    align-items: center;
    align-self: center;
    justify-content: center;
}


/* adaptive */

@media (max-width: 700px) {
    .MainMenu_question_title_mobile__2tfKY {
        display: flex;
        flex-shrink: 0;
        width: 100%;
        margin-bottom: 20px;
        -webkit-box-align: center;
        -ms-flex-align: center;
        text-align: center;
        align-self: center;
        color: black;
        margin-top: 20px;
        width: 330px;
        font-size: 25px;
        line-height: 30px;
    }
}

@media (max-width: 992px) {
    /* это будет показано при разрешении монитора до 930 пикселей */
    .MainMenu_tests_body_left__2BpLE {
        display: flex;
        flex-shrink: 0;
        flex-direction: column;
        width: 90%;
        margin-top: 60px;
    }
    .MainMenu_question_body__2oCrI {
        display: flex;
        flex-shrink: 0;
        flex-direction: column;
        width: 95%;
        align-items: center;
        justify-content: center;
    }
    .MainMenu_test_body_right__3rh4k {
        padding-left: 0;
        display: flex;
        flex-shrink: 0;
        flex-direction: column;
        width: 100%;
    }
    .MainMenu_score_frame__3Uat4 {
        margin-top: 29px;
        margin-bottom: 15px;
        display: flex;
        flex-shrink: 0;
        flex-direction: column;
        width: 100%;
        align-items: center;
    }
    .MainMenu_topic_frame_menu___hAEN {
        display: flex;
        flex-shrink: 0;
        width: 90%;
        align-self: center;
        align-items: center;
        justify-content: center;
    }
    .MainMenu_description__J0eJW {
        width: 60%;
        text-align: center;
        -ms-grid-row-align: center;
        align-self: center;
        color: gray;
        margin-top: 20px;
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .MainMenu_tests_body_left__2BpLE {
        display: flex;
        flex-shrink: 0;
        flex-direction: column;
        width: 90%;
    }
    .MainMenu_btn_wrap__3UqrG {
        width: 80%;
        -ms-flex-item-align: center;
        -ms-grid-row-align: center;
    }
    .MainMenu_skip__2W6RZ {
        width: 80%;
        margin: auto;
        align-self: center;
    }
    .MainMenu_title__2Gi82 {
        margin-left: 10px;
    }
}

@media (max-width: 325px) {
    /* это будет показано при разрешении монитора до 930 пикселей */
    .MainMenu_question_body__2oCrI {
        display: flex;
        flex-shrink: 0;
        flex-direction: column;
        width: 95%;
        margin-top: 29px;
        align-items: center;
    }
    .MainMenu_tests_body_left__2BpLE {
        display: flex;
        flex-shrink: 0;
        flex-direction: column;
        width: 100%;
        align-self: center;
    }
    .MainMenu_test_body_right__3rh4k {
        display: flex;
        flex-shrink: 0;
        flex-direction: column;
        width: 100%;
        align-self: center;
    }
    .MainMenu_skip__2W6RZ {
        display: flex;
        flex-shrink: 0;
        flex-direction: column;
        width: 60%;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-self: center;
    }
    .MainMenu_title__2Gi82 {
        display: flex;
        flex-shrink: 0;
        margin-top: 30px;
    }
    .MainMenu_video_explanation_frame__2WI3P {
        width: 100%;
        height: 90%;
    }
    .MainMenu_video__k3wLX {
        width: 100%;
        height: 250px;
        background-color: black;
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        align-self: center;
        justify-content: center;
    }
}

@media (min-width: 993px) {
    .MainMenu_page__2bokk {
        display: flex;
        flex-direction: column;
        padding: 105px 0px;
        position: absolute;
        width: 950px;
        left: calc(50% - 950px/2);
        top: 0px;
    }
}

@media only screen and (min-height: 750px) and (max-height: 850px) {
    .MainMenu_page__2bokk {
        display: flex;
        flex-shrink: 0;
        flex-direction: column;
        padding: 105px 0px;
        position: absolute;
        width: 95%;
        height: 120vh;
        left: calc(50% - 95%/2);
        top: 0px;
        background-color: #FFFFFF;
        align-items: center;
    }
}

@media only screen and (min-height: 700px) and (max-height: 749px) {
    .MainMenu_page__2bokk {
        display: flex;
        flex-shrink: 0;
        flex-direction: column;
        padding: 10px 0px;
        position: absolute;
        width: 95%;
        height: 100%;
        left: calc(50% - 95%/2);
        top: 0px;
        background-color: #FFFFFF;
        align-items: center;
    }
}

@media only screen and (min-height: 600px) and (max-height: 699px) {
    .MainMenu_page__2bokk {
        display: flex;
        flex-shrink: 0;
        flex-direction: column;
        padding: 10px 0px;
        position: absolute;
        width: 95%;
        height: 100%;
        left: calc(50% - 95%/2);
        top: 0px;
        background-color: #FFFFFF;
        align-items: center;
    }
}

@media only screen and (min-height: 520px) and (max-height: 599px) {
    .MainMenu_page__2bokk {
        display: flex;
        flex-shrink: 0;
        flex-direction: column;
        padding: 10px 0px;
        position: absolute;
        width: 95%;
        height: 100%;
        left: calc(50% - 95%/2);
        top: 0px;
        background-color: #FFFFFF;
        align-items: center;
    }
}
.ZNO_zno_choose__1rmZA {
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    padding: 2% 5px;
    min-width: 96%;
    max-width: 96%;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
    border: 1px white solid;
    border-radius: 10px;
    align-self: center;
    margin-bottom: 7px;
    transition: 0.2s linear;
    margin-top: 5px;
}

.ZNO_zno_choose__1rmZA:focus {
    outline: 0;
}

.ZNO_zno_choose__1rmZA:hover {
    border: 1px grey solid;
    cursor: pointer;
}

.ZNO_wrapper__1waII {
    padding: 16px;
}

.ZNO_description__3lALn {
    display: flex;
    flex-shrink: 0;
    flex-direction: row;
    margin-bottom: 16px;
}

.ZNO_results__1x5es {
    display: flex;
    flex-shrink: 0;
    flex-direction: row;
}

.ZNO_active__1luTR {
    border: 1px black solid;
}
.Header_end__16_sj {
    align-items: center;
    text-align: center;
    width: 210px;
    height: 38.5px;
    background: #FFFFFF;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 10px;
    line-height: 22px;
    transition: 0.2s ease-in-out;
    vertical-align: middle;
    font-family: Gilroy;
    font-size: 25px;
    line-height: 30px;
}

.Header_end__16_sj:focus {
    outline: 0;
}

.Header_end__16_sj:hover {
    background-color: black;
    color: white;
    cursor: pointer;
}
.Header_header__DeK98{
    width: 100%;
    background-color: white;
    display: flex;
    font-size: 25px;
    line-height: 30px;
    flex-direction: column;
}

.Header_nav_panel__1mite{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 15px;
    align-items: baseline;
    margin-top: 15px;
    font-size:18px;
    
}
.Header_nav__3_NSc{
    font-size:25px;
    line-height: 30px;
}
.Header_timer__13fFy{
    display: flex;
    flex-direction: row;
}
.Header_title__3e75N{
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}

@media (max-width: 992px) {
    /* это будет показано при разрешении монитора до 930 пикселей */
    .Header_header__DeK98{
        height: 100%;
        display: flex;
        font-size: 16px;
        line-height: 20px;
        flex-direction: column;
        height: 20%;
    }
    .Header_page__34USF {
        display: flex;
        flex-direction: column;
        padding: 5px 0px;
        position: absolute;
        width: 95%;
        left: calc(50% - 95%/2);
        top: 0px;
        font-size: 16px;
    }
}
.Courses_header_mobile__1N_vk {
    -moz-appearance: none;
    appearance: none;
    -webkit-appearance: none;
    width: 90%;
    background-color: white;
    display: flex;
    flex-shrink: 0;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 30px;
    padding: 0px 15px;
    margin-top: 15px;
    font-size: 25px;
    line-height: 25px;
}

.Courses_logo__2oK-6 {
    font-size: 25px;
    line-height: 30px;
}

.Courses_header__2uTAN {
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    width: 100%;
}

.Courses_question_header__1fIJK {
    display: flex;
    flex-direction: row;
    flex-direction: row;
}

.Courses_icon__3F15q {
    width: 100%;
    height: 100%;
}

.Courses_title__tPNAo {
    display: inline-flex;
    flex-shrink: 0;
    flex-direction: row;
    font-size: 36px;
    line-height: 44px;
    font-weight: 600;
    margin-bottom: 8px;
}

.Courses_sub_title__2f3PY {
    display: inline-block;
    display: -moz-inline-stack;
    align-items: baseline;
}

.Courses_sub_title_icon__6wTMO {
    display: inline-block;
    display: -moz-inline-stack;
}

.Courses_description__1oeFd {
    margin-top: 20px;
    margin-bottom: 49px;
}


/* courses */

.Courses_courses_wrapper__XsBmW {
    display: flex;
    flex-shrink: 0;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
}

.Courses_exit__bdyYm {
    width: 210px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.Courses_wrap__9yyEH {
    display: flex;
    flex-shrink: 0;
    flex-direction: row;
}

.Courses_end__1ejEd {
    align-items: center;
    align-items: center;
    text-align: center;
    width: 130px;
    height: 38.5px;
    background: #FFFFFF;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 10px;
    line-height: 22px;
    transition: 0.2s ease-in-out;
    vertical-align: middle;
    font-family: Gilroy;
    font-size: 18px;
    font-weight: bold;
    line-height: 22px;
}

.Courses_end__1ejEd:focus {
    outline: 0;
}

.Courses_end__1ejEd:hover {
    background-color: black;
    color: white;
    cursor: pointer;
}

@media (max-width: 992px) {
    .Courses_page__3AmVV {
        display: flex;
        flex-shrink: 0;
        flex-direction: column;
        padding: 10px 0px;
        position: absolute;
        -moz-appearance: none;
        appearance: none;
        -webkit-appearance: none;
        height: 220%;
        left: calc(50% - 95%/2);
        top: 0px;
        background-color: #FFFFFF;
    }
}

@media (min-width: 993px) {
    .Courses_page__3AmVV {
        display: flex;
        flex-direction: column;
        padding: 10px 0px;
        position: absolute;
        width: 950px;
        left: calc(50% - 950px/2);
        top: 0px;
        -moz-appearance: none;
        appearance: none;
        -webkit-appearance: none;
    }
}

@media (max-width: 663px) {
    .Courses_courses_wrapper__XsBmW {
        display: flex;
        flex-shrink: 0;
        flex-direction: column;
        justify-content: space-around;
        align-self: center;
        -moz-appearance: none;
        appearance: none;
        -webkit-appearance: none;
    }
}
.Course_course_frame__Q4F9n {
    width: 285px;
    height: 120px;
    border: 1px solid #0000002f;
    background-color: white;
    box-sizing: border-box;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    margin: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: 0.2s linear;
    cursor: pointer;
}

.Course_course_frame_no_hover__2T7ie {
    width: 285px;
    height: 120px;
    border: 1px solid #0000002f;
    background-color: white;
    box-sizing: border-box;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    margin: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: 0.2s linear;
    cursor: pointer;
}

.Course_course_frame__Q4F9n:hover {
    border: 1px black solid;
}

.Course_wrapper__3SJTp {
    display: flex;
    flex-direction: row;
    align-self: center;
}

.Course_icon__1ZJGW {
    width: 64px;
    height: 64px;
}

.Course_course__1QeVn {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.Course_course_text__2sQSi {
    margin-left: 23px;
    font-weight: 600;
    width: 150px;
}
.Footer_footer__34QH0 {
    display: flex;
    font-size: 18px;
    flex-direction: column;
    line-height: 25px;
}

.Footer_info__1fO28 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.Footer_logo__2noru {
    margin-top: 30px;
    margin-bottom: 15px;
    font-size: 24px;
}

.Footer_title__17voH {
    margin-top: 30px;
    margin-bottom: 15px;
}

.Footer_description__wJJIp {
    margin: 10px 0px;
    font-size: 18px;
    line-height: 20px;
}

.Footer_names_frame__1W7Cp {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.Footer_names__2xRod {
    margin: 20px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.Footer_links__21JQJ {
    margin: 10px 0px;
    cursor: pointer;
}

a {
    color: black;
}

a:focused {
    color: black;
}

.Footer_links__21JQJ:hover {
    opacity: 1;
}

.Footer_info_mavka__1-yNU {
    width: 250px;
}

.Footer_names_object__3NgA4 {
    width: 250px
}

@media (max-width: 663px) {
    .Footer_info__1fO28 {
        display: flex;
        flex-direction: column;
        margin: 15px 0px;
        align-items: center;
        text-align: center;
    }
    .Footer_names__2xRod {
        margin: 20px 0px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        text-align: center;
    }
    .Footer_title__17voH {
        align-items: center;
        text-align: center;
    }
}
.Survey_btn_turn_back__3Qpbv {
    width: 270px;
    height: 48.5px;
    background: #000000;
    border-radius: 10px;
    color: white;
    font-weight: bold;
    font-family: Gilroy;
    font-size: 18px;
    line-height: 22px;
    transition: 0.2s ease-out;
    border: none;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.28);
    -ms-grid-row-align: center;
    align-self: center;
    margin-top: 14px;
    margin-bottom: 7px;
}

.Survey_btn_turn_back__3Qpbv:hover {
    opacity: 0.9;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.56);
    cursor: pointer;
}

.Survey_page__3vzzd {
    display: flex;
    flex-direction: column;
    padding: 55px 0px;
    position: absolute;
    height: 120%;
    width: 945px;
    left: calc(50% - 945px/2);
    top: 0px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.Survey_header__PHwi2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.Survey_title__J_qio {
    font-size: 36px;
    line-height: 44px;
    font-weight: 600;
    margin-bottom: 25px;
}

.Survey_description__2lbyb {
    margin-bottom: 49px;
}

/* courses */

.Survey_courses_wrapper__25lJ0 {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
}
.Survey_btn_turn_back__2cOZp {
    width: 170px;
    height: 48.5px;
    background: #000000;
    border-radius: 10px;
    color: white;
    font-weight: bold;
    font-family: Gilroy;
    font-size: 18px;
    line-height: 22px;
    transition: 0.2s ease-out;
    border: none;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.28);
    align-self: center;
    margin-top: 14px;
    margin-bottom: 7px;
}

.Survey_btn_turn_back__2cOZp:hover {
    opacity: 0.9;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.56);
    cursor: pointer;
}

.Survey_page__3nNVr {
    display: flex;
    flex-direction: column;
    padding: 55px 0px;
    position: absolute;
    height: 120%;
    width: 945px;
    left: calc(50% - 945px/2);
    top: 0px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}
.Survey_header__4hBvD{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.Survey_title__2aej7{
    font-size: 36px;
    line-height: 44px;
    font-weight: 600;
    margin-bottom: 25px;
}
.Survey_description__To7AC{
    margin-bottom: 49px;
}


/* courses */
.Survey_courses_wrapper__1Zje8{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
}
@font-face {
    font-family: Gilroy;
    src: url("https://raw.githubusercontent.com/mavka-org/mavka-web/master/src/fonts/Gilroy-Regular.woff");
}

@font-face {
    font-family: Gilroy;
    src: url("https://raw.githubusercontent.com/mavka-org/mavka-web/master/src/fonts/Gilroy-RegularItalic.woff");
    font-style: italic;
}

@font-face {
    font-family: Gilroy;
    src: url("https://raw.githubusercontent.com/mavka-org/mavka-web/master/src/fonts/Gilroy-Semibold.woff");
    font-weight: bold;
}

@font-face {
    font-family: Gilroy;
    src: url("https://raw.githubusercontent.com/mavka-org/mavka-web/master/src/fonts/Gilroy-SemiboldItalic.wofff");
    font-weight: bold;
    font-style: italic;
}

.Landing_page__xuogf {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: white;
    font-family: Gilroy;
    font-size: 24px;
    line-height: 29px;
}

.Landing_wrapper__1w7Ki {
    padding-top: 110px;
    padding-left: 110px;
    padding-right: 110px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.Landing_frame_1__1f63G {
    display: flex;
    flex-direction: column;
    margin-right: 25px;
}

.Landing_title__3N5jV {
    display: flex;
    flex-direction: row;
}

.Landing_beta__1DqL_ {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    text-align: center;
    width: 70px;
    height: 30px;
    background: #0EFB71;
    border-radius: 10px;
    justify-content: center;
    margin-left: 12px;
}

.Landing_big_title__2ZNHu {
    font-size: 56px;
    line-height: 69px;
    font-weight: 600;
    margin-top: 30px;
    margin-bottom: 30px;
}

.Landing_next__33Whx {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    width: 282px;
    height: 42.5px;
    background: #111111;
    border-radius: 10px;
    color: white;
    border: none;
    font-size: 24px;
    line-height: 29px;
    font-family: Gilroy;
    text-align: center;
    vertical-align: middle;
    transition: 0.3s ease-out;
    border: 1px black solid;
    margin-top: 20px;
    margin-bottom: 20px;
    cursor: pointer;
}

.Landing_next__33Whx:hover {
    color: black;
    background-color: white;
}

.Landing_next__33Whx:focus {
    outline: 0;
}

.Landing_desc_2__2KRnQ {
    font-size: 24px;
    line-height: 29px;
    margin: 25px;
}

.Landing_icon__n9HRk {
    margin-left: 80px;
    display: block;
    min-width: 65px;
    min-height: 65px;
}

.Landing_frame_2__3XT6F {
    display: flex;
    flex-direction: column;
    border-left: 1px black solid;
}

.Landing_icon_row__15CBK {
    display: flex;
    flex-direction: row;
}

@media screen and (max-width: 814px) {
    .Landing_wrapper__1w7Ki {
        padding-top: 10px;
        padding-left: 10px;
        padding-right: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
    .Landing_frame_2__3XT6F {
        display: flex;
        flex-direction: column;
        border-left: none;
    }

}
@font-face {
    font-family: Gilroy;
    src: url("https://raw.githubusercontent.com/mavka-org/mavka-web/master/src/fonts/Gilroy-Regular.woff");
}

@font-face {
    font-family: Gilroy;
    src: url("https://raw.githubusercontent.com/mavka-org/mavka-web/master/src/fonts/Gilroy-RegularItalic.woff");
    font-style: italic;
}

@font-face {
    font-family: Gilroy;
    src: url("https://raw.githubusercontent.com/mavka-org/mavka-web/master/src/fonts/Gilroy-Semibold.woff");
    font-weight: bold;
}

@font-face {
    font-family: Gilroy;
    src: url("https://raw.githubusercontent.com/mavka-org/mavka-web/master/src/fonts/Gilroy-SemiboldItalic.wofff");
    font-weight: bold;
    font-style: italic;
}
